<template>
    <el-dialog
        tabindex="-1"
        width="800px"
        top="40px"
        :title="`Dela konto ${selectedAccount.account} ${selectedAccount.name}`"
        :visible.sync="visible"
        :before-close="closeModal"
        :close-on-click-modal="false"
        @closed="resetModalState"
    >
        <el-form ref="selectedAccountsData">
            <div class="flex flex-col">
                <AccountInfo
                    v-if="periodIsMoved"
                    :period="balanceSheetData.period"
                    :divided-accounts-array="dividedAccountsArray"
                    :selected-account="selectedAccount"
                    :is-period="true"
                    :annual-report-id="annualReportId"
                    @account-reset="handleAccountReset"
                    :titles-data="titlesData"
                    :selected-option-sru="getSelectedSruCode"
                    :find-account-data="findAccountData"
                    :financial-page="financialPage"
                />
                <AccountInfo
                    v-if="previousPeriodIsMoved"
                    :period="balanceSheetData.previousPeriod"
                    :divided-accounts-array="dividedAccountsArray"
                    :selected-account="selectedAccount"
                    :is-period="false"
                    :annual-report-id="annualReportId"
                    @account-reset="handleAccountReset"
                    :titles-data="titlesData"
                    :selected-option-sru="getSelectedSruCode"
                    :find-account-data="findAccountData"
                    :financial-page="financialPage"
                />
                <el-form-item v-if="!originalAccountIsEmpty" class="w-full flex flex-col" prop="">
                    <div v-if="hasMultipleDividedAccounts">
                        <h1 class="font-bold mb-2">Omför konto till:</h1>
                        <el-select v-model="setOption" class="w-full">
                            <el-option :label="setOption.name" :value="setOption.sruCode" />
                        </el-select>
                    </div>
                    <div v-else>
                        <h1 class="font-bold mb-2">Välj rad att omföra konto till:</h1>
                        <el-select v-model="selectedOption" class="w-full">
                            <el-option v-for="(option, title) in titlesData" :key="title" :label="option.name" :value="option.sruCode" />
                        </el-select>
                    </div>
                    <el-table :data="tableData" style="width: 100%" class="text-sm pt-4">
                        <el-table-column label="År" prop="year">
                            <template slot-scope="scope">
                                <p>
                                    {{ scope.row.year == "period" ? formatDate(balanceSheetData.period.startDate) : formatDate(balanceSheetData.previousPeriod.startDate) }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column label="Aktuellt belopp" prop="sum">
                            <template slot-scope="scope">
                                <p>{{ displayClosingBalance(scope.row) | swedishNumberFormat() }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="Flytta belopp" prop="sumMoved">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.sumMoved" :disabled="inputDisabledMoveAll(scope.row) || displayClosingBalance(scope.row) === 0" />
                            </template>
                        </el-table-column>
                        <el-table-column label="Flytta allt" prop="moveAll">
                            <template slot-scope="scope">
                                <el-checkbox v-model="scope.row.moveAll" :disabled="displayClosingBalance(scope.row) === 0" />
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </div>
        </el-form>
        <span v-if="!originalAccountIsEmpty" slot="footer" class="dialog-footer">
            <el-button @click="saveMoveAccounts" type="success">Spara</el-button>
            <el-button @click="closeModal" type="warning">Avbryt</el-button>
        </span>
    </el-dialog>
</template>

<script>
import Api from "../../annualReport.api.js";
import moment from "moment";

export default {
    props: {
        annualReportId: {
            type: String,
        },
        visible: {
            type: Boolean,
            default: false,
        },
        clientId: {
            type: String,
        },
        clientInfo: {
            type: Object,
        },
        selectedAccountsData: {
            type: Array,
        },
        selectedAccount: {
            type: Object,
        },
        titlesData: {
            type: Array,
        },
        balanceSheetData: {
            type: Object,
        },
        isPeriod: {
            type: Boolean,
        },
    },

    components: {
        AccountInfo: () => import("../../incomeStatement/components/AccountInfo.vue"),
    },

    data() {
        return {
            formData: {},
            accountsPlanTypes: [],
            selectedOption: "Välj ny rad",
            tableData: [
                { year: "period", sum: 0, sumMoved: 0, moveAll: false },
                { year: "previousPeriod", sum: 0, sumMoved: 0, moveAll: false },
            ],
            selectedRow: this.selectedAccountsData.find(account => account.type === 5),
            dividedAccountsArray: [],
            setOption: "",
            setSruCodeFromOption: "",
            isSaving: false,
            isResetModalVisible: false,
            selectedPeriod: "",
            amountMessage: "",
            financialPage: "balanceSheet",
        };
    },

    watch: {
        tableData: {
            deep: true,
            handler(newValue) {
                newValue.forEach(row => {
                    if (row.moveAll) {
                        row.sumMoved = " ";
                    }
                });
            },
        },
        selectedAccount: {
            immediate: true,
            handler(newValue) {
                if (newValue && newValue.title) {
                    if (this.selectedOption !== "Välj ny rad") {
                        this.selectedOption = newValue.title;
                    } else {
                        this.setOption = newValue.title;
                    }
                }
                this.getDividedAccountsArray();
                this.getSettedOption();
            },
        },
        selectedAccountsData: {
            immediate: true,
            deep: true,
            handler(newValue) {
                this.selectedRow = newValue.find(account => account.type === 5) || null;
            },
        },
    },

    methods: {
        formatDate(date) {
            if (!date) return;
            return moment(date).format("YYYY");
        },
        resetModalState() {
            this.formData = {};
        },
        getDividedAccountsArray() {
            const filteredAccountsArray = this.balanceSheetData.row
                .filter(account => account.type === 4 && account.account === this.selectedAccount.account)
                .map(account => {
                    const relatedAccount = this.balanceSheetData.row.find(acc => acc.sruCode === account.sruCode && acc.type === 5);
                    return {
                        ...account,
                        isInRow: relatedAccount ? relatedAccount.name : null,
                    };
                });
            this.dividedAccountsArray = filteredAccountsArray;
        },
        getSettedOption() {
            if (this.hasMultipleDividedAccounts) {
                if (this.originalAccount && this.dividedFromAccount) {
                    if (!this.selectedRowIsOriginal) {
                        this.setOption = this.originalAccount.originalRowName;
                        this.setSruCodeFromOption = this.originalAccount.sruCode;
                    } else if (this.selectedRowIsOriginal) {
                        this.setOption = this.dividedFromAccount.isInRow;
                        this.setSruCodeFromOption = this.dividedFromAccount.sruCode;
                    }
                }
            }
        },

        closeModal() {
            this.$emit("close");
        },
        async saveMoveAccounts() {
            if (this.isSaving) return;
            this.isSaving = true;

            try {
                const findAccount = this.findAccountData;
                const movedAmountPeriod = String(this.tableData[0].moveAll === true ? this.selectedAccount.period : this.tableData[0].sumMoved).replace(",", ".");
                const movedAmountPreviousPeriod = String(this.tableData[1].moveAll === true ? this.selectedAccount.previousPeriod : this.tableData[1].sumMoved).replace(",", ".");
                const emptyValues = ["", "0", null];
                if (this.hasMultipleDividedAccounts) {
                    this.selectedOption = this.setSruCodeFromOption;
                }
                if (this.selectedOption.length !== 4 || this.selectedRow.sruCode === this.selectedOption) {
                    this.$notify.error({ title: "Du har inte valt ny rad" });
                } else if (emptyValues.includes(movedAmountPeriod) && emptyValues.includes(movedAmountPreviousPeriod)) {
                    this.$notify.error({ title: "Du har inte valt summa" });
                } else {
                    const accountToChange = {
                        ...findAccount,
                        moveWholeAmountPeriod: this.tableData[0].moveAll,
                        moveWholeAmountPreviousPeriod: this.tableData[1].moveAll,
                        movedAmountPeriod: movedAmountPeriod,
                        movedAmountPreviousPeriod: movedAmountPreviousPeriod,
                        previousSruCode: findAccount.sruCode,
                        movedToRowName: this.titlesData.find(option => option.sruCode === this.selectedOption).name,
                    };
                    accountToChange.sruCode = this.selectedOption;

                    await Api.updateBalanceSheetDetails(this.annualReportId, accountToChange);
                    this.moveMessage(movedAmountPeriod, movedAmountPreviousPeriod, emptyValues);
                    this.$emit("close-accounts-section-when-moved", false);
                    this.$emit("update-balance-sheet-details");
                    this.$emit("close");
                }
            } catch (error) {
                console.error(error);
                this.$notify.error({ title: "Ett fel uppstod vid sparandet" });
            } finally {
                this.isSaving = false;
            }
        },
        moveMessage(movedAmountPeriod, movedAmountPreviousPeriod, emptyValues) {
            if (!emptyValues.includes(movedAmountPeriod) && !emptyValues.includes(movedAmountPreviousPeriod)) {
                this.amountMessage = "Flyttat " + movedAmountPeriod + " SEK & " + movedAmountPreviousPeriod + " SEK från ";
            } else {
                if (!emptyValues.includes(movedAmountPeriod) && emptyValues.includes(movedAmountPreviousPeriod)) {
                    this.amountMessage = "Flyttat " + movedAmountPeriod + " SEK från ";
                } else if (emptyValues.includes(movedAmountPeriod) && !emptyValues.includes(movedAmountPreviousPeriod)) {
                    this.amountMessage = "Flyttat " + movedAmountPreviousPeriod + " SEK från ";
                }
            }

            const movedMessage = this.amountMessage + this.selectedRow.name + " till " + this.titlesData.find(option => option.sruCode === this.selectedOption).name;
            this.$notify.success({ title: movedMessage, duration: 15000 });
        },
        inputDisabledMoveAll(row) {
            return row.moveAll;
        },
        displayClosingBalance(row) {
            if (row.year === "period") {
                return this.selectedAccount.period;
            } else {
                return this.selectedAccount.previousPeriod;
            }
        },
        handleAccountReset() {
            this.$emit("close-accounts-section-when-moved", false);
            this.$emit("update-balance-sheet-details");
            this.$emit("close");
        },
    },

    mounted() {
        this.getDividedAccountsArray();
    },

    computed: {
        hasMultipleDividedAccounts() {
            return this.dividedAccountsArray.length > 1;
        },
        periodIsMoved() {
            if (this.hasMultipleDividedAccounts) {
                return this.selectedAccount.periodIsMoved && this.dividedAccountsArray[1].period !== 0;
            }
            return false;
        },
        previousPeriodIsMoved() {
            if (this.hasMultipleDividedAccounts) {
                return this.selectedAccount.previousPeriodIsMoved && this.dividedAccountsArray[1].previousPeriod !== 0;
            }
            return false;
        },
        originalAccountIsEmpty() {
            const findOriginal = this.dividedAccountsArray.find(account => account.originalRowName === account.isInRow);
            return findOriginal?.period === 0 && findOriginal?.previousPeriod === 0 && this.selectedAccount.sruCode === findOriginal?.sruCode;
        },
        originalAccount() {
            return this.dividedAccountsArray.find(account => account.originalRowName === account.isInRow);
        },
        originalSruCode() {
            return this.titlesData.find(option => option.name === this.dividedFromAccount.originalRowName).sruCode;
        },
        dividedFromAccount() {
            return this.dividedAccountsArray.find(account => account.originalRowName !== account.isInRow);
        },
        selectedRowIsOriginal() {
            return this.selectedRow.name === this.originalAccount.originalRowName;
        },
        findAccountData() {
            return this.selectedAccountsData.find(account => account.name === this.selectedAccount.name && account.account === this.selectedAccount.account);
        },
        getSelectedSruCode() {
            return this.setSruCodeFromOption;
        },
    },
};
</script>
